import React from "react"
import PropTypes from "prop-types"

import Box from "./box"
import Stack from "./stack"
import Heading from "./heading"

const StepsHeading = ({ subtitle, children, as }) => {
  /* random number between 1 and 4 */
  const randomNumber = Math.floor(Math.random() * (4 - 1 + 1) + 1)

  /* angles for rotated box */
  const angles = {
    1: "-2deg",
    2: "-1deg",
    3: "1deg",
    4: "2deg",
  }

  const randomAngle = angles[randomNumber]

  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      <Box
        sx={{
          color: "primary",
          position: "absolute",
          width: 6,
          left: [-4, -4, -8],
          top: 3,
          transform: `rotate(${randomAngle})`,
          zIndex: 2,
        }}
      >
        <svg viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 0V14L11 7L0 0Z" fill="currentColor" />
        </svg>
      </Box>
      <Stack space={3}>
        <Box
          sx={{
            bg: "contrast",
            py: 2,
            px: 3,
            display: "inline-block",
            transform: `rotate(${randomAngle})`,
          }}
        >
          <Heading as="p" level={4} color="background">
            {subtitle}
          </Heading>
        </Box>
        <Heading as={as} level={2}>
          {children}
        </Heading>
      </Stack>
    </Box>
  )
}

StepsHeading.propTypes = {
  as: PropTypes.string,
  subtitle: PropTypes.string,
}

StepsHeading.defaultProps = {
  subtitle: "Schritt 1",
}

export default StepsHeading
